<template>
	<div class="widget-min-box" :widget-id="widget.id" :widget-type="widget.type">
		<slot v-if="widgetLoad">
			<div class="widget-min-box-content text-center p-2">
				<div class="widget-loading"></div>
			</div>
		</slot>
		<slot v-else>
			<div v-if="isNaN(widget.id)" class="widget-min-box-list" :class="{'min-show': responsive.rightSection}">
				<slot v-if="widgetLoad">
					<div class="text-center p-2">
						<div class="widget-loading"></div>
					</div>
				</slot>
				<slot v-else>
					<div class="list-widget list-dash-items list-widget-structure" data-list-type="watchList-cat">
						<div class="list-row l-head">
							<div class="list-col l-item">
								<span class="width-fix">ترتیب</span>
							</div>
							<div class="list-col l-title">
								<span>فهرست</span>
							</div>
							<div class="list-col l-item"><span class="width-fix">ویرایش</span></div>
							<div class="list-col l-item"><span class="width-fix">حذف</span></div>
						</div>

						<draggable v-model="watchLists" group="watchLists" @start="drag=true" @end="saveWatchListsSort()" ghost-class="drag-ghost" v-if="watchLists">
							<template v-for="watchList in watchLists">
								<div :key="watchList.id" class="list-row item-draggable hover bold">
									<div class="list-col l-item pointer">
										<i class="uil uil-arrows-v list-drag"></i>
									</div>
									<div class="list-col l-title l-title-widget-structure" @click="openWatchList(watchList.id); makeResponsive(2)">
										<span>{{ watchList.title }}</span>
									</div>
									<div class="list-col l-item pointer" @click="open_watch_list_edit_modal(watchList.id)">
										<i class="uil uil-edit-alt"></i>
									</div>
									<div class="list-col l-item red-color pointer" @click="removeWatchList(watchList.id)">
										<i class="uil uil-trash remove-row"></i>
									</div>
								</div>
							</template>
						</draggable>

					</div>
					<div class="list-widget-add-container">
						<i class="fa fa-plus list-widget-add-icon" aria-hidden="true" @click="addWatchList()"></i>
						<input class="list-widget-input-add" type="text" placeholder="افزودن فهرست جدید" v-model="newWatchList" @keyup.enter="addWatchList()">
					</div>
				</slot>

			</div>

			<div class="widget-min-box-content" :class="{'min-show': responsive.leftSection}">
				<slot v-if="widgetLoad">
					<div class="text-center p-2">
						<div class="widget-loading"></div>
					</div>
				</slot>
				<slot v-else>

					<div class="list-row l-head">
						<div class="list-col l-item">
							<span class="fa fa-arrow-right widget-min-back-to-list" @click="makeResponsive(1)"></span>
						</div>

						<!-- <div class="list-col l-title d-flex" @click="open_watch_list_edit_modal()">
							<span v-if="activeWatchList">{{ activeWatchList.title }}</span>
							<i class="uil-edit-alt edit-favorite" v-if="activeWatchList && activeWatchList.title"></i>
						</div> -->

						<div class="list-col l-title d-flex">
							<span v-if="activeWatchList">مشاهده فهرست {{ activeWatchList.title }}</span>
						</div>

						<div v-if="activeWatchList" class="list-col l-item pointer hover head-color">
							<span class="btn btn-primary btn-sm list-col-left-btn" @click="open_watch_list_add_item_modal()">
								<div class="fa fa-plus l-item-btn-pages"></div>
								افزودن
							</span>
						</div>
						<!-- <div class="list-col l-item pointer hover head-color show-widget-settings-modal" @click="open_settings_modal()" v-if="editable && wrapper != 'dashboard'">
							<span>
								<div class="fa fa-cog l-item-btn-pages"></div>
								تنظیمات
							</span>
						</div> -->
					</div>

					<div class="tgju-widget-content full-padding">
						
						<slot v-if="activeWatchList.market_ids.length == 0">
							<div class="popup-dash-empty">
								<i class="uil uil-exclamation" aria-hidden="true"></i>
								<span>مقداری برای نمایش وجود ندارد</span>
							</div>
						</slot>
						<slot v-else>
							<div class="list-widget list-dash-items list-widget-structure" v-if="market_values && market_values.length">
								<div class="list-row l-head">
									<div class="list-col l-title">
										<span>عنوان</span>
									</div>
									<div class="list-col l-title">
										<span>فعلی</span>
									</div>
									<div class="list-col l-title">
										<span>بیشترین</span>
									</div>
									<div class="list-col l-title">
										<span>کمترین</span>
									</div>
									<div class="list-col l-title">
										<span>تغییر</span>
									</div>
									<div class="list-col l-title">
										<span>زمان</span>
									</div>
									<div class="list-col l-item">
										<span class="width-fix">حذف</span>
									</div>
								</div>

								<div v-for="market_value in market_values" :key="market_value.id">
									<div  class="list-row item-draggable hover bold">
										<div class="list-col l-title l-title-widget-structure max-70">
											<span>{{ market_value.title }}</span>
										</div>
										<div class="list-col l-title l-title-widget-structure">
											<span>{{ formatPrice(market_value.p) }}</span>
										</div>
										<div class="list-col l-title l-title-widget-structure">
											<span>{{ formatPrice(market_value.h) }}</span>
										</div>
										<div class="list-col l-title l-title-widget-structure">
											<span>{{ formatPrice(market_value.l) }}</span>
										</div>
										<div class="list-col l-title l-title-widget-structure">
											<span v-if="market_value.dt == 'high' " class="high color-green badge-green dir">{{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
											<span v-else-if="market_value.dt == 'low' " class="low color-red badge-red dir">{{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
											<span v-else>{{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
										</div>
										<div class="list-col l-title l-title-widget-structure">
											<span>
												{{ market_value.t }}
											</span>
										</div>
										<div class="list-col l-item red-color pointer" @click="removeWatchListItemLink(market_value.item_id)">
											<i class="uil uil-trash remove-row"></i>
										</div>
									</div>
								</div>
							</div>
							
							<div class="text-center p-2" v-else>
								<div class="widget-loading"></div>
							</div>
						</slot>

					</div>
				</slot>
			</div>
		</slot>
	</div>
</template>

<style lang="scss">
	.high.dir, .low.dir {
		max-height: 25px;
		margin: 10px;
	}
</style>

<script>
	// این کامپوننت اجزای ویجت فهرست ها(واچ لیست) در داشبورد را نمایش می دهد
	import draggable from 'vuedraggable';
	import Centrifuge from "centrifuge";
	import moment from "moment-jalaali";

	export default {
		name: 'WatchList',
		props: ['widget', 'data', 'editable', 'wrapper'],
		components: {
			draggable,
		},
		data() {
			return {
				watchLists: [],
				activeWatchList: {
					market_ids : []
				},
				market_values: undefined,
				newWatchList: undefined,
				responsive: {
					rightSection: true,
					leftSection: false,
					leftPanel: false,
				},
				widgetLoad: true,
				ListLoad: false,
			}
		},
		computed: {
            current_route() {
                return this.$store.state.currentRoute;
            },
        },
		mounted() {
			this.$watch('data', function(newValue, oldValue) {
				// بروز رسانی داده ها کامپونتت با لود مجدد
				if ( Object.keys(newValue).length && ( typeof oldValue == 'undefined' || !Object.keys(oldValue).length ) ) {
					// تزریق داده های ورود به کامپوننت
					this.loadData();

					// در صورتی که واچ لیستی وجود داشت به صورت پیش فرض انتخاب شود
					if (!isNaN(this.widget.id) && this.widget.data.selected_watchList > 0) {
						this.openWatchList(this.widget.data.selected_watchList);
					} else {
						this.getMarketValue();	
					}
					// فعال کردن ارتباط سوکت برای بروزرسانی لحظه ای قیمت مارکت					
					// this.socketMarket();

				}
			}, {immediate: true});
		},

		methods: {	
			// این متد ارتباط با سوکت را برقرار میکند و سپس داده های موجود در واچ لیست را آپدیت میکند
			socketMarket() {
				var self = this;
				var centrifuge = new Centrifuge("wss://stream.tgju.org/connection/websocket");

				centrifuge.subscribe("tgju:stream", function(ctx) {
					for (var i in ctx.data) {
						var item = ctx.data[i].split("|");
						if (item[0] === 'market') {
							for (const [index , market_item] of Object.entries(self.market_values)) {
								if(self.activeWatchList.market_ids.length > 0) {
									if(market_item.item_id == item[1]) {
										self.market_values[index].d  =  item[10];
										self.market_values[index].dp =  item[11];
										self.market_values[index].dt =  item[12];
										self.market_values[index].h  =  item[7];
										self.market_values[index].l  =  item[8];
										self.market_values[index].p  =  item[6];
										self.market_values[index].t  =  item[13];
									}
								}
							}
						}
					}
				});

				centrifuge.connect();
			},
			// این متد برای نمایش زمان می باشد
			time() {
				return  moment().format('LTS');
			},
			// این متد برای نمایش و جدا سازی قیمت و نرخ ها از هم دیگر  می باشد .
			formatPrice(value) {
				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
			// این تابع وظیفه تزریق داده های ورودی به کامپوننت را دارد
			loadData() {
				this.watchLists = this.data.watchLists;

				if ( this.watchLists.length ) {
					this.activeWatchList = this.watchLists[0];
				}

				this.widgetLoad = false;
			},
			
			// افزودن یک واچ لیست جدید
			addWatchList() {
				// در صورتی که عنوان خالی بود ادامه ندهد پروسه را
				if ( !this.newWatchList ) return;

				let id = 'id-' + new Date().getTime();

				this.watchLists.push({
					id,
					title: this.newWatchList,
					market_ids: []
				});

				let api_data = {
					title: this.newWatchList,
					market_ids: null,
				};

				// ارسال درخواست به سرور جدید ذخیره سازی واچ لیست
				this.$helpers.makeRequest('POST', '/watchlist/save', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						let inserted_id = api_response.data.response.id;

						this.watchLists.forEach((watchList, index) => {
							if ( watchList.id == id ) {
								watchList.id = inserted_id;
							}
						});
					}
				});

				this.newWatchList = '';
				this.openWatchList(id);
			},

			// این متد جهت حذف واچ لیست استفاده میشود
			removeWatchList(id) {

				this.$helpers.confirm('هشدار حذف!', {
					message: 'آیا از حذف این مورد اطمینان دارید؟',
				}).then((confirmed) => {
					if ( confirmed ) {
						
						this.activeWatchList = {
							market_ids : []
						};

						let index = this.$helpers.getIndex(this.watchLists, id);

						// حذف کردن واچ لیست از واچ لیست هایی فعلی در صفحه
						this.watchLists.splice(index, 1);
						
						let next_index = index - 1;
						if ( next_index < 0 )
							next_index = 0;

						// در صورتی که واچ لیست دیگر وجود داشت به صورت اتوماتیک انتخاب شود 
						if(this.watchLists.length > 0) {
							this.openWatchList(this.watchLists[next_index].id);
						}

						// ارسال درخواست به سرور جهت حذف واچ لیست
						this.$helpers.makeRequest('DELETE', '/watchlist/delete/' + id).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								//
							}
						});	
					}
				});
			},

			// این متد مرتب سازی واچ لیست را هندل میکند
			saveWatchListsSort() {
				let sort = [];
				this.watchLists.forEach(watchList => {
					sort.push(watchList.id);
				});

				let api_data = {
					sort: JSON.stringify(sort),
				};

				this.$helpers.makeRequest('POST', '/watchlist/sort', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						//
					}
				});
			},

			// این متد وظیفه این را دارد پس از کلیک روی واچ لیست نرخ های آن را لود میکند
			openWatchList(id) {
				let index = this.$helpers.getIndex(this.watchLists, id);

				this.activeWatchList = this.watchLists[index];

				this.market_values = [];

				this.getMarketValue();

			},

			// این متد وظیفه هندل کردن باز کردن مودال جهت افزودن نماد جدید را دارد
			open_watch_list_add_item_modal() {

				// در صورتی که واچ لیستی وجود نداشت به گیام مناسب چاپ کند
				if(this.watchLists.length == 0) {
					Vue.prototype.$toast.warn({
						title: 'توجه!',
						message: 'شما باید حداقل یک واچ لیست ایجاد کرده باشید'
					});
					return;
				}

				//ایجاد یک کال بک یونیک
				let callbackEvent = this.$router.currentRoute.name + '_AddWatchList_' + new Date().getTime();

				// ست کردن تنظیمات مودال مورد نظر
				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'WatchListAdd.vue',
					config: {
						title: 'افزودن نماد',
						icon: 'uil uil-plus-circle with-icon',
						smallModal: true,
						data: {},
					}
				};
				// باز کردن مودال
				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (response) => {
					// هندل کال بک مودال
					if ( response.action == 'addWatchList' ) {
						let index = this.activeWatchList.market_ids.indexOf(response.data.market_id);
						if (index < 0) {
							this.activeWatchList.market_ids.push(response.data.market_id);
							// درخواست ثبت و ذخیره آیتم واچ لیست
							this.saveWatchListItem();
							this.openWatchList(this.activeWatchList.id);

							Vue.prototype.$toast.success({
								title : 'موفق',
								message : 'با موفقیت اقزوده شد',
							});
						}
					}
				});
			},

			// این متد برای ویرایش عنوان واچ لیست است
			open_watch_list_edit_modal(id = 0) {

				if (id > 0) {
					let index = this.$helpers.getIndex(this.watchLists, id);
					this.activeWatchList = this.watchLists[index];
				}

				let callbackEvent = this.$router.currentRoute.name + '_EditWatchLists_' + new Date().getTime();

				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'WatchListEdit.vue',
					config: {
						title: 'ویرایش فهرست',
						icon: 'uil uil-edit-alt with-icon',
						smallModal: true,
						data: {
							watchList: this.$helpers.unbindObject(this.activeWatchList),
						},
					}
				};

				

				this.$root.$emit('open_modal', modal_data);
				
				
				this.$root.$on(callbackEvent, (response) => {
					if ( response.action == 'editWatchList' ) {
						this.activeWatchList.title = response.data.title;

						let api_data = {
							title: this.activeWatchList.title,
						};

						this.$helpers.makeRequest('POST', '/watchlist/edit/' + this.activeWatchList.id, api_data).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								//
							}
						});
					}
				});
			},

			// این متد وظیفه دریافت نرخ اولیه شاخص ها را دارد
			getMarketValue() {
				if(this.activeWatchList.market_ids.length > 0) {
					let api_data = {
						market_ids: this.activeWatchList.market_ids.join(),
					};
					this.$helpers.makeRequest('GET', '/watchlist/market', api_data).then(api_response => {
						if ( api_response.status == 200 ) {
							this.market_values = api_response.data.response.detail;
						}
					});
				}
			},

			// این متد وظیفه ارسال درخواست ذخیره ایتم های یک واچ لیست را برعهده دارد
			saveWatchListItem() {
				let api_data = {
					market_ids: JSON.stringify(this.$helpers.unbindObject(this.activeWatchList.market_ids)),
				};
				this.$helpers.makeRequest('POST', '/watchlist/market-ids-save/' + this.activeWatchList.id, api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						//
					}
				});
			},

			// این متد جهت باز کردن مودال تنظیمات واچ لیست را بر عهده دارد .
			open_settings_modal() {
				let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

				let modal_data = {
					modal: 'widget_settings_modal',
					callbackEvent,
					component: 'Settings/WatchList.vue',
					config: {
						data: {
							title: this.widget.title,
							watchLists : this.watchLists,
							selected_watchList : this.widget.data.selected_watchList ? this.widget.data.selected_watchList : 0
						}
					}
				};

				modal_data = this.$helpers.unbindObject(modal_data);

				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (settings) => {
					this.widget.title = settings.title;
					this.widget.data = settings.data;

					if (this.widget.data.selected_watchList > 0) {
						this.openWatchList(this.widget.data.selected_watchList);
					}

					let widget = this.$helpers.unbindObject(this.widget);

					this.$helpers.saveWidget(widget);
				});
			},
			// این متد برای حذف کردن آیتم های واچ لیست را بر عهده دارد .
			removeWatchListItemLink(market_id) {
				let index = this.activeWatchList.market_ids.indexOf(market_id);

				this.activeWatchList.market_ids.splice(index, 1);

				let index_market_values = this.market_values.findIndex(p => p.item_id == market_id);

				this.market_values.splice(index_market_values, 1);

				this.saveWatchListItem();

				Vue.prototype.$toast.success({
					title : 'موفق',
					message : 'با موفقیت حذف شد',
				});
			},

			makeResponsive(mode) {
				if ( mode == 1 ) {
					this.responsive.rightSection = true;
					this.responsive.leftSection = false;
				} else if ( mode == 2 ) {
					this.responsive.rightSection = false;
					this.responsive.leftSection = true;
				}
			},
			
		},
	}
</script>